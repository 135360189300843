'use strict';

jQuery(document).ready(function($) {
      const $contact_us_form = $('#contact_us_form');
      const $industry = $contact_us_form.find('#industry');
      const $monthly_bookings= $contact_us_form.find('#monthly_bookings');
      const $monthly_bookings_div = $contact_us_form.find('#monthly_bookings_div');

      showHideBookings($industry.val());

      $industry.change(function() {
          showHideBookings($(this).val());
      });

      function showHideBookings(industryVal) {
          if(industryVal.match(/^fashion|beauty|retail$/i)) {
              $monthly_bookings_div.hide();
              $monthly_bookings.val('');
          }
          else {
              $monthly_bookings_div.show();
          }
      }
});
