'use strict';

jQuery(document).ready(function($) {
  const $iconSlider = $('#iconsSlider');
  const $dots = $('.slider-dots');

  $iconSlider.on('slide.bs.carousel', function () {
    $dots.hide();
  });

  $iconSlider.on('slid.bs.carousel', function () {
    $dots.fadeToggle();
  });


    // setInterval(function() {
    //     $('#home-slideshow > div:first')
    //         .fadeOut(1000)
    //         .next()
    //         .fadeIn(1000)
    //         .end()
    //         .appendTo('#home-slideshow');
    // },  4000);
});
